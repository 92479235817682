<template>
    <div class="login-page w-100">
        <v-form
                class="w-100"
                ref="loginForm"
                v-model="valid"
                @submit.prevent="submitHandler"
        >
            <!-- email -->
            <div class="mt-4">
        <span class="darktext--text font-18 font-600">{{
            $t("forms.email")
            }}</span>
                <base-input
                        v-model="obj.email"
                        class="mt-1"
                        :type="$t('forms.email')"
                        placeholder="mail@info.com"
                        :rules="emailRules"
                ></base-input>
            </div>

            <!-- password -->
            <div class="mt-6">
        <span class="darktext--text font-18 font-600">{{
            $t("forms.password")
            }}</span>
                <base-password
                        v-model="obj.password"
                        class="mt-1"
                        placeholder="****************"
                ></base-password>
            </div>

            <!-- forget passowrd -->
            <router-link to="/register/forget-password">
        <span class="darkprimary--text font-18 font-600 d-block mt-2">{{
            $t("forms.forgetPassword")
            }}</span>
            </router-link>

            <!-- btn -->
            <base-register-btn
                    class="mt-7"
                    :title="$t('forms.login')"
                    type="submit"
                    :loading="loading"
            >
            </base-register-btn>

            <div class="text-center mt-7">
                <p class="greytext--text font-18">
                    {{ $t("forms.noAccount") }}
                    <router-link to="/register"
                    ><span class="font-18 darkprimary--text cursor-pointer font-700">{{
                        $t("forms.createAccount")
                        }}</span></router-link
                    >
                </p>
            </div>
        </v-form>
    </div>
</template>

<script>
export default {
    data: (vm) => ({
        valid: false,
        loading: false,
        emailRules: [
            (v) => !!v || vm.$t("forms.emailRequired"),
            (v) => /.+@.+\..+/.test(v) || vm.$t("forms.emailValid"),
        ],
        obj: {},
    }),
    methods: {
        async submitHandler() {
            this.loading = true;
            let formData = new FormData();
            formData.append("email", this.obj.email);
            formData.append("password", this.obj.password);

            let {data} = await this.$axios.post("sign-in", formData);
            this.loading = false;
            if (data) {
                console.log(data.data.data.has_sign)
                // if (data.data.data.has_sign == 0) {
                //     data.data.type === "doctor" ? this.$router.push(`/sign-contract?token=${data.data.token}`)
                //         : this.$router.push(`/sign-contract-hospital?token=${data.data.token}`);

                // } else 
                if (data.data.data.approval == 0) {
                    this.$router.push("/complete");
                } else {
                    this.$store.dispatch("showSnack", {
                        text: this.$t("messages.loggedSuccess"),
                        color: "success",
                    });
                    this.loading = false;
                    this.$store.dispatch("setUser", data.data);
                }
            } else {
                this.$store.dispatch("showSnack", {
                    text: this.$t("messages.errorEmailPassword"),
                    color: "error",
                });
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
